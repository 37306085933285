import React from "react";
import "./ReleaseNotes.css";
import {
  AppContainer,
  AppMainContent,
  Icon,
  Table,
} from "@abb/abb-common-ux-react";
import { useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import { envSettings } from "../../../EnviornmentSettings/Settings";

interface IReleaseNotesProps {
  showReleaseNotes: boolean;
  onCloseReleaseNotes: () => void;
}
const ReleaseNotes = (props: IReleaseNotesProps) => {
  const handleEscape = (e: any) => {
    if (e.keyCode === 27) {
      props.onCloseReleaseNotes();
    }
  };
  const commonState = useSelector((state: IState) => state.commonState);
  React.useEffect(() => {
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, []);
  const currentTime = new Date();
  let formattedDate = currentTime.toLocaleString();
  formattedDate = formattedDate.replace(/\//g, "-");
  formattedDate = formattedDate.replace(/,/g, "");
  return (
    <div className="release-notes-wrap">
      <div className="notes-heading">
        <span>Release Notes </span>
        <div>
          <Icon
            name="abb/close"
            className="notes-close-icon"
            onClick={() => props.onCloseReleaseNotes()}
          />
        </div>
      </div>
      <AppContainer
        className={`views-container ${
          commonState.theme === "light"
            ? "light-view-container"
            : "dark-view-container"
        }`}
        theme={commonState.theme}
      >
        <AppMainContent>
          <div className="release-note-container">
            <div className="release-note-content">
              <p />
              <div className="release-notes-header-wrap">
                <p>
                  <p className="release-note-contentheaders">About</p>
                  {
                    <div className="release-notes-app-details">
                      Edgenius Configuration Utility is a part of the Edgenius
                      platform. It simplifies the application management for
                      disconnected Edgenius.
                      <br />
                      It helps export edge configuration as a portable file and
                      download software for installation.
                    </div>
                  }
                </p>
                <br />
              </div>
              <div className="release-notes-app-details"></div>
              <div className="release-note-version">{`VERSION  2.0.0`}</div>
              {/* <div className="release-note-time-stamp">{`${formattedDate}`}</div> */}
              <hr />
              <p>
                <p className="release-note-contentheaders">What's new</p>
                {
                  <ul>
                    <li>
                      Adaptation for Edgenius Workplace installation as a part
                      of base platform.
                    </li>
                  </ul>
                }
                <br />
              </p>
              <div className="release-note-version">{`VERSION  1.2.0`}</div>
              {/* <div className="release-note-time-stamp">{`${formattedDate}`}</div> */}
              <hr />
              <p>
                <p className="release-note-contentheaders">What's new</p>
                {
                  <ul>
                    <li>
                      Download Edgenius Software and PI Digital Apps from
                      software download tab.
                    </li>
                  </ul>
                }
                <br />
              </p>
              <div className="release-note-version">{`VERSION  1.1.0`}</div>
              {/* <div className="release-note-time-stamp">{`${formattedDate}`}</div> */}
              <hr />
              <p>
                <p className="release-note-contentheaders">What's new</p>
                {
                  <ul>
                    <li>
                      Create an offline edge configuration in a multi-tenant
                      cloud based utility without connecting to actual edge.
                    </li>
                    <li>
                      Manage Edge configurations to add or remove Edgenius
                      applications.
                    </li>
                    <li>
                      Apply latest updates for Edgenius applications and system
                      modules.
                    </li>
                    <li>
                      Export configurations as a YAML file to use it for
                      installation and updating of offline edge device.
                    </li>
                  </ul>
                }
              </p>
              <br />
              <p>
                <p className="release-note-contentheaders">Note:</p>
                <div className="release-notes-app-details">
                  Edgenius configuration utility does not connect to
                  disconnected edge devices, therefore saved configuration may
                  differ from the actual Edge devices.
                  <br /> Application engineer is responsible for managing
                  configuration and installation/ update of disconnected edge
                  device.
                </div>
              </p>
            </div>
          </div>
        </AppMainContent>
      </AppContainer>
    </div>
  );
};

export default ReleaseNotes;
