import React, { useEffect } from "react";
import "./Documentation.css";
import Tree, { ITreeDataObj } from "../Tree/Tree";
import { Icon, AppMainContent } from "@abb/abb-common-ux-react";
import { helpPageTreeData } from "../../../Utils/Constants";
import { RouteComponentProps, withRouter } from "react-router";
import * as commonActions from "../../Action/ActionCommon";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../Reducer";
import CommonLoader from "../CommonLoader/CommonLoader";

const refs: any = {};
helpPageTreeData.map((item: any) => {
  refs[item.id] = React.createRef();
});

const Documentation = (props: RouteComponentProps) => {
  const [tocData, handleExpandCollapse] = React.useState([...helpPageTreeData]);
  const [selectedNodeId, setSelectedNodeId] = React.useState(
    helpPageTreeData[0].id
  );
  const [nodeClickCount, setNodeClickCount] = React.useState(0);
  const [showLeftPane, toggleLeftPane] = React.useState(true);
  React.useEffect(() => {
    const elmnt = document.getElementById(selectedNodeId);
    if (elmnt && elmnt !== null) {
      elmnt?.scrollIntoView();
    }
  }, [nodeClickCount]);
  const commonState = useSelector((state: IState) => state.commonState);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(commonActions.getPreference());
  }, []);
  const clickedNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    setSelectedNodeId(clickedObj.id);
    setNodeClickCount(nodeClickCount + 1);
  };
  const handleExpandCollapseNode = (event: any, clickedObj: ITreeDataObj) => {
    event.stopPropagation();
    if (clickedObj.isExpandable) {
      const updatedData = [...tocData];
      updatedData.map((item) => {
        if (item.id === clickedObj.id) {
          item.isExpanded = !item.isExpanded;
        }
      });
      if (clickedObj.isExpanded) {
        updatedData.map((item) => {
          if (
            item["id"].indexOf(clickedObj.id) >= 0 &&
            item.level > clickedObj.level
          ) {
            item.isExpanded = false;
            item.isVisible = false;
          }
        });
      } else {
        updatedData.map((item) => {
          if (
            item.id.indexOf(clickedObj.id) >= 0 &&
            item.level === clickedObj.level + 1
          ) {
            item.isExpanded = false;
            item.isVisible = true;
          }
        });
      }
      handleExpandCollapse([...updatedData]);
    }
  };

  const handleDocClick = (clickedId: string) => {
    let modifiedId = clickedId;
    const updatedData = [...tocData];
    updatedData.reverse();
    updatedData.some((item) => {
      if (clickedId.indexOf(item.id) >= 0 && item.isVisible) {
        modifiedId = item.id;
        return true;
      }
      return false;
    });
    setSelectedNodeId(modifiedId);
  };

  const tooglePane = () => {
    toggleLeftPane(!showLeftPane);
  };
  return (
    <React.Fragment>
      {/* {commonState.isLoading ? <CommonLoader /> : <div />} */}
      {!commonState.isPreferenceLoaded ? (
        <div className="theme-loader-mask" />
      ) : (
        <div />
      )}
      <AppMainContent>
        <div className="docs">
          <div className="docs-view">
            <div className="menu" onClick={tooglePane}>
              <Icon name="abb/menu" sizeClass="large" />
            </div>
            {showLeftPane ? (
              <Tree
                data={tocData}
                selectedNodeId={selectedNodeId}
                handleExpandCollapse={handleExpandCollapseNode}
                handleNodeSelection={clickedNode}
              />
            ) : (
              <React.Fragment />
            )}
          </div>
          <DisplayNewDoc onDocClick={handleDocClick} />
        </div>
      </AppMainContent>
    </React.Fragment>
  );
};

const DisplayNewDoc = (props: any) => {
  return (
    <div className="doc-view-container" id="scrolling-div">
      <div>
        <div
          id="%1.introduction%"
          className="level-1-padding"
          ref={refs["%1.introduction%"]}
          onMouseOver={() => props.onDocClick("%1.introduction%")}
        >
          <p className="headers-document first-header">1 Introduction</p>
          <p>
            Edgenius configuration utility is a part of Edgenius platform
            solution to manage Edge devices. It simplifies managing applications
            for a disconnected Edge without connecting to the actual Edge
            device.
          </p>
          <p>
            This utility is hosted as a multi-tenant cloud application. An
            Application engineer can log into the tenant space using a web
            browser with internet access,define an edge in the utility, manage
            Edgenius applications for the edge device and export the
            configuration as a portable file. The Application engineer can
            download the latest versions of the applications in Download
            Software tab.
          </p>
          <p>
            Edgenius configuration utility supports the following key
            operations:
          </p>

          <ul>
            <li>Add an edge device.</li>
            <li>
              Create edge configuration, select applications to be installed on
              the edge.
            </li>
            <li>Download the Edgenius software and PI Digital Apps.</li>
            <li>
              Manage edge configuration, for saved edges by updating the
              applications and the system modules when updates are available.
            </li>
            <li>Export edge configuration as a file.</li>
          </ul>
          <p>
            <i>
              Note: These operations manage device configuration saved in the
              utility but not on the actual edge device. Saving, updating or
              deleting carried out in this configuration does not reflect on the
              actual edge until a configuration file is exported and used via
              dedicated Installation process.
            </i>
          </p>
        </div>

        <div
          id="%2.edgenius%"
          className="level-1-padding"
          ref={refs["%2.edgenius%"]}
          onMouseOver={() => props.onDocClick("%2.edgenius%")}
        >
          <p className="headers-document first-header">
            2 Working with Edgenius Configuration Utility
          </p>
          <p>
            This section provides instructions required to use the Edgenious
            Configuration Utility.
          </p>
        </div>

        <div
          id="%2.edgenius%%2.1.createdge%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.1.createdge%"]}
          onMouseOver={() => props.onDocClick("%2.edgenius%%2.1.createdge%")}
        >
          <p className="headers-document">2.1 Creating a New Edge</p>
          <p>
            To create a new edge, click on New Edge and enter the details such
            as: Name and Location as shown in figure 2.1.
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-1.png"
              className="common-image-class"
            />
          </p>
        </div>

        <div
          id="%2.edgenius%%2.2.editedge%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.2.editedge%"]}
          onMouseOver={() => props.onDocClick("%2.edgenius%%2.2.editedge%")}
        >
          <p className="headers-document">2.2 Editing an Edge</p>
          <p>
            Click on the edit icon (see figure 2.2) to modify the Name or
            Location details associated with the edge.
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-2.png"
              className="common-image-class"
            />
          </p>
        </div>

        <div
          id="%2.edgenius%%2.3.downloadsoftware%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.3.downloadsoftware%"]}
          onMouseOver={() =>
            props.onDocClick("%2.edgenius%%2.3.downloadsoftware%")
          }
        >
          <p className="headers-document">2.3 Software Download</p>
          <p>
            Software Downloads consists of: Edgenius software (base platform,
            connects and applications) and extended custom applications.
          </p>
          <p>
            Only users with the appropriate access rights will be allowed to
            download the software. The user rights are managed by the tenant
            administrator.
          </p>
          <p>
            Note:
            <ul>
              <li>
                Ensure that the machine used to download the software is
                connected to the internet and the connection is stable.
              </li>
              <li>
                To download the software, click on the edge and navigate to
                Software Download. Clicking on the software will generate a link
                to be copied and pasted in a browser window to download the
                software.
              </li>
              <li>
                Ensure that the latest versions of the applications are updated
                in the edge configuration and the configuration file is exported
                for installation of the software package. Failure to do so might
                result in issues during installation.
              </li>
              <li>
                The software download link is set to expire after a certain time
                period and is indicated in the URL popup window.
              </li>
            </ul>
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-9.png"
              className="common-image-class"
            />
          </p>
        </div>

        <div
          id="%2.edgenius%%2.4.applicationadd%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.4.applicationadd%"]}
          onMouseOver={() =>
            props.onDocClick("%2.edgenius%%2.4.applicationadd%")
          }
        >
          <p className="headers-document">
            2.4 View and Add applications on an edge
          </p>
          <p>
            Click on the edge to View the applications installed on the edge and
            all the applications available for download. To Add an application
            to the edge, click the + button as shown in figure 2.4
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-4.png"
              className="common-image-class"
            />
          </p>
        </div>

        <div
          id="%2.edgenius%%2.5.applicationdelete%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.5.applicationdelete%"]}
          onMouseOver={() =>
            props.onDocClick("%2.edgenius%%2.5.applicationdelete%")
          }
        >
          <p className="headers-document">
            2.5 Delete an application from the edge
          </p>
          <p>Click the trash icon to Delete the application from the edge.</p>
        </div>

        <div
          id="%2.edgenius%%2.6.exportconfig%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.6.exportconfig%"]}
          onMouseOver={() => props.onDocClick("%2.edgenius%%2.6.exportconfig%")}
        >
          <p className="headers-document">2.6 Export the configuration</p>
          <p>
            Click the Export button to export the configuration. A *.yml file is
            downloaded when a configuraiton is exported.
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-5.png"
              className="common-image-class"
            />
          </p>
        </div>

        <div
          id="%2.edgenius%%2.7.systeminfo%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.7.systeminfo%"]}
          onMouseOver={() => props.onDocClick("%2.edgenius%%2.7.systeminfo%")}
        >
          <p className="headers-document">2.7 View system info</p>
          <p>
            Click on the info icon (see figure 2.6) to view the system modules:
            Core, Base and Orchestrator modules associated with the edge.
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-6.png"
              className="common-image-class"
            />
          </p>
        </div>
        <div
          id="%2.edgenius%%2.8.systemupdate%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.8.systemupdate%"]}
          onMouseOver={() => props.onDocClick("%2.edgenius%%2.8.systemupdate%")}
        >
          <p className="headers-document">2.8 Update the system modules</p>
          <p>
            When any of the system modules are updated, an update available text
            is displayed alongside the icon. Click on the update icon, to view
            the modules with updates and start the updation as shown in
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-7.png"
              className="common-image-class"
            />
          </p>
        </div>
        <div
          id="%2.edgenius%%2.9.appicationupdate%"
          className="level-2-padding"
          ref={refs["%2.edgenius%%2.9.appicationupdate%"]}
          onMouseOver={() =>
            props.onDocClick("%2.edgenius%%2.9.appicationupdate%")
          }
        >
          <p className="headers-document">2.9 Update the application modules</p>
          <p>Click on the update icon to update any application on the edge.</p>
          <p>
            Note: Updating Applications will result in an automatic update of
            the System Modules to the latest versions.
          </p>
          <p>
            <img
              src="images/UserDocsImages/Fig2-8.png"
              className="common-image-class"
            />
          </p>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Documentation);
